import React from "react";
import { useCookies } from "react-cookie";
import styles from "./CookieConsent.module.scss";
import mixpanel from "mixpanel-browser";

const CookieConsent = () => {
  const [_, setCookie] = useCookies(["cookieConsent"]); // eslint-disable-line @typescript-eslint/no-unused-vars
  const giveCookieConsent = () => {
    mixpanel.track("Cookie consent accepted");
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.cookiesHeader}>Cookies:</div>
        <div>
          <b>This website</b>
          <ul className={styles.info}>
            <li>Uses cookies to enhance the user experience </li>
            <li>Uses a NSFW filter, but should be treated as NSFW </li>
            <li>
              Uses our{" "}
              <a
                className={styles.link}
                href="https://haveibeentrained.com/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>{" "}
              and does not retain any searched images{" "}
            </li>
            <li>
              Is protected by reCAPTCHA and the Google{" "}
              <a
                className={styles.link}
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                className={styles.link}
                rel="noopener noreferrer"
                href="https://policies.google.com/terms"
                target="_blank"
              >
                Terms of Service
              </a>{" "}
              apply
            </li>
          </ul>
        </div>

        <button
          type="button"
          className={styles.button}
          onClick={giveCookieConsent}
        >
          I understand
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
