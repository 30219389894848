"use client";
import { useEffect } from "react";

import { useAppSelector } from "../app/GlobalRedux/store";
import { useDispatch } from "react-redux";

import { ListTypeId } from "@/types";
import HibtService from "@/services/hibtService";
import { setDoNotTrainData } from "../app/GlobalRedux/Features/doNotTrainListDataSlice";
import { setSnackBar } from "@/app/GlobalRedux/Features/snackBarSlice";
import Logging from "./Logging";

const useGetDoNotTrainListData = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const loadData = async () => {
    if (!user?.id_token) {
      return null;
    }

    try {
      const { lists } = await HibtService.getLists(user.id_token);

      const doNotTrainList = lists.find(
        (list) => list.type_id === ListTypeId.DO_NOT_TRAIN
      );

      if (!doNotTrainList) return;

      const { id } = doNotTrainList;

      // walk through pages in a loop
      const { records } = await HibtService.getListSelections(
        user.id_token,
        id,
        '["id", "DESC"]',
        `[0, 25]`,
        true
      );

      dispatch(setDoNotTrainData(records));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Logging.networkError(
        `API Error - load Do Not Train list items ${user.username}`,
        {
          pageName: "useGetDoNotTrainListData",
          errorMessage: error.message,
          errorCode: error.code,
          statusCode: error.response?.status,
        }
      );

      return error;
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setDoNotTrainData([]));
    }

    loadData();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useGetDoNotTrainListData;
