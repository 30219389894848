"use client";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useAppSelector } from "../../app/GlobalRedux/store";
import { setSnackBar } from "../../app/GlobalRedux/Features/snackBarSlice";

import styles from "./SnackBar.module.scss";

const SnackBar = () => {
  const dispatch = useDispatch();
  const { open, message, variant } = useAppSelector((state) => state.snackBar);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    let timerClose: ReturnType<typeof setTimeout>;

    if (open) {
      setAnimateOut(false);
      timerClose = setTimeout(() => {
        setAnimateOut(true);

        setTimeout(() => {
          dispatch(setSnackBar({ open: false }));
        }, 2000);
      }, 2000);
    } else if (!open && animateOut) {
      timerClose = setTimeout(() => {
        setAnimateOut(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timerClose);
    };
  }, [open, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open && !animateOut) {
    return null;
  }

  const handleCloseClick = () => {
    setAnimateOut(true);

    setTimeout(() => {
      dispatch(setSnackBar({ open: false }));
    }, 500);
  };

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.closed]: animateOut,
        [styles.error]: variant === "error" || variant === "doNotTrainError",
      })}
    >
      <div className={styles.textWrapper}>
        <p className={styles.text}>{message} </p>
        {(variant === "doNotTrain" || variant === "doNotTrainError") && (
          <a href={`/dashboard/#0`} className={styles.linkButton}>
            {`"Do Not Train" list`}
          </a>
        )}

        <IconButton onClick={handleCloseClick}>
          <CloseIcon
            fontSize="small"
            sx={{
              color: "#fff",
            }}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default SnackBar;
