"use client";

import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import mixpanel from "mixpanel-browser";
import { useDispatch } from "react-redux";

import CookieConsent from "./CookieConsent/CookieConsent";
import SnackBar from "./SnackBar/SnackBar";

import useCheckAccessToken from "@/utils/useCheckAccessToken";
import useGetDoNotTrainListData from "@/utils/useGetDoNotTrainListData";
import { useAppSelector } from "@/app/GlobalRedux/store";
import AuthService from "@/services/authService";
import { setUserInformation } from "@/app/GlobalRedux/Features/authSlice";
import Logging from "@/utils/Logging";

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY || "", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
  loaded: () => {
    mixpanel.track("Mixpanel successfully initialized");
  },
});

interface ProviderProps {
  children: React.ReactNode;
}

const Provider = ({ children }: ProviderProps) => {
  const [cookies] = useCookies(["cookieConsent"]);
  const dispatch = useDispatch();
  const userInfo = useAppSelector((state) => state.auth.user);

  // initial call to get user's preferred username and email and set it globally
  const getUserInfo = async () => {
    if (!userInfo) return;

    try {
      const { data, status } = await AuthService.getUserInfo(
        userInfo.access_token
      );

      if (status === 200) {
        dispatch(
          setUserInformation({
            ...userInfo,
            email: data.email,
            username: data.preferred_username,
          })
        );
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Logging.networkError("API Error - get User Information", {
        pageName: "Provider.tsx",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [userInfo?.access_token]); // eslint-disable-line react-hooks/exhaustive-deps

  useCheckAccessToken();
  useGetDoNotTrainListData();

  mixpanel.track("Initial Page Load");

  return (
    <div>
      {children}

      {!cookies.cookieConsent && <CookieConsent />}

      <SnackBar />
    </div>
  );
};

export default Provider;
