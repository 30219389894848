"use client";
import Logging from "@/utils/Logging";
import { storyblokInit, apiPlugin } from "@storyblok/react";
import { useEffect } from "react";

interface StoryblokProviderProps {
  children: React.ReactNode;
}

export default function StoryblokProvider({
  children,
}: StoryblokProviderProps) {
  const loadData = () => {
    try {
      storyblokInit({
        accessToken: process.env.NEXT_PUBLIC_STORYBLOK_API_KEY,
        use: [apiPlugin],
        apiOptions: {
          region: "us",
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Logging.networkError("StoryBlok Error - storyblock init", {
        pageName: "StoryblokProvider",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    }
  };

  useEffect(() => {
    loadData();
  });

  return children;
}
