"use client";
import { useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";

import AuthService from "@/services/authService";
import { useAppSelector } from "../app/GlobalRedux/store";
import { useDispatch } from "react-redux";

import {
  setAuthenticationPhase,
  setUserInformation,
  setListsInformation,
} from "../app/GlobalRedux/Features/authSlice";
import { AuthPhase } from "@/types";
import Logging from "./Logging";

const useCheckAccessToken = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const refreshTimeout = useRef<NodeJS.Timeout>();

  const handleLoggingOut = () => {
    dispatch(setAuthenticationPhase(AuthPhase.LOGOUT));
    dispatch(setUserInformation(null));
    dispatch(setListsInformation(undefined));
  };

  const handleSetUserInformation = (user: {
    username: string;
    access_token: string;
    refresh_token: string;
    id_token: string;
    email: string;
  }) => {
    dispatch(setUserInformation(user));
  };

  useEffect(() => {
    if (!user) {
      clearTimeout(refreshTimeout.current);
      handleLoggingOut();
      return;
    }

    const handleRefreshAccessToken = async () => {
      try {
        const newTokens = await AuthService.refreshAccessToken(
          user.username,
          user.refresh_token
        );

        // check if empty, means an error happened, let's log out
        if (!newTokens) {
          handleLoggingOut();
          return;
        }

        const { access_token, id_token } = newTokens as {
          access_token: string;
          id_token: string;
        };

        // this has to be typed as any because JwtDecode doesnt have an email property
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { email } = jwtDecode(id_token || "") as any;

        handleSetUserInformation({
          username: user.username,
          access_token: access_token,
          refresh_token: user.refresh_token,
          id_token: id_token,
          email: email,
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Logging.networkError(`API Error - AuthService refreshAccessToken`, {
          pageName: "useCheckAccessToken",
          errorMessage: error.message,
          errorCode: error.code,
          statusCode: error.response?.status,
        });

        handleLoggingOut();
        return;
      }
    };

    if (user.access_token) {
      const { exp } = jwtDecode(user.access_token);
      const now = Date.now() / 1000;

      if (exp) {
        if (exp < now) {
          // refresh immediately
          handleRefreshAccessToken();
        } else {
          // otherwise set a timeout to refresh it when it expires
          const timeout = (exp - now - 60) * 1000;
          refreshTimeout.current = setTimeout(() => {
            handleRefreshAccessToken();
          }, timeout);

          // return a cleanup function that cancels the timeout
          return () => {
            clearTimeout(refreshTimeout.current);
          };
        }
      } else {
        // can't determine when token needs to refresh
        // let's log them out, or else we may get weird errors
        handleLoggingOut();
        return;
      }
    } else {
      handleLoggingOut();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useCheckAccessToken;
